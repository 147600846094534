import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import Auth from "src/auth/Auth";
import { controlText1_style } from "src/components/common/ControlText";
import { useNavigate } from "react-router-dom";
import {
  MxReactIcon,
  Gear,
  LinkOut,
} from "src/componentLibrary/react/mx-icon-react";
import { useOutsideClickEvent } from "src/hooks/useOutsideClickEvent";
import { AppColors } from "src/components/common/Styling";
import { tracking } from "src/tracking";
import { useUserContext } from "src/auth/UserContext";
import { useAuthorization } from "src/hooks/useAuthorization";
import { InlineH4 } from "src/components/common/InfoText";
import RoleBasedComponent from "src/components/common/RoleOnlyComponent";
import { UserRole } from "src/types/roles";
import config from "src/configuration";

export const UserMenuContainer = styled.div`
  height: 100%;
  border-left: 2px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  font-size: ${controlText1_style.fontSize};
  color: ${controlText1_style.activeColor};
  font-weight: ${controlText1_style.activeWeight};
  max-width: 380px;

  /* this is needed to fix an IE11 bug. Sorry about that. */
  @media all and (-ms-high-contrast: none) {
    flex-basis: 380px;
  }
  @media (max-width: 800px) {
    flex-basis: auto;
  }
`;

export const UserMenuHeader = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;

  @media (max-width: 800px) {
    padding: 0px 16px;
  }
`;

export const TitleStyle = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  padding-right: 60px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ArrowStyle = styled.span``;

export const UserMenuItemContainer = styled.ul`
  position: absolute;
  background-color: ${AppColors.neutral["light-gray-9"]};
  width: 294px;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 8px;
  display: flex;
  z-index: 100;
  right: 0;
`;

export const UserMenuItem = styled.li`
  padding: 16px 0px 16px 30px;
  & > a {
    line-height: 1;
    display: flex;
    align-items: center;
  }
`;

const UserMenuSeparator = styled.hr`
  color: ${AppColors.neutral["light-navy-9"]};
  border-top: 1px solid ${AppColors.neutral["light-navy-9"]};
  height: 1px;
  width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Icon = styled.svg`
  stroke: ${AppColors.neutral.navy};
  stroke-width: 2px;
  width: 18px;
  height: 18px;
  fill: ${AppColors.neutral.navy};
`;

export const StatusLozenge = styled(InlineH4)`
  background-color: ${AppColors.semantic.green["light-msr-green-6"]};
  color: ${AppColors.primary["msr-green"]};
  font-weight: 400;
  border-radius: 20px;
  padding: 8px 12px;
  margin-left: 20px;
`;

interface IUserMenu {
  user?: string;
  organizationId?: string;
}

export const UserMenu: React.FC<IUserMenu> = (props: IUserMenu) => {
  const navigate = useNavigate();
  const menuItemsRef = useRef(null);
  const handleOutsideClick = () => {
    if (showMenu) {
      setShowMenu(false);
    }
  };
  useOutsideClickEvent(handleOutsideClick, menuItemsRef);
  const [showMenu, setShowMenu] = useState(false);
  const { organizationId } = props;
  // const { currentOrganizationId } = useContext(CurrentOrganizationContext);
  const authorization = useAuthorization();

  const {
    userData: { id: userId },
  } = useUserContext();

  const sendEmail = () => {
    window.open("mailto:" + config.SUPPORT_EMAIL, "_blank");
  };

  const logout = () => {
    Auth.logout();
  };

  const clickHandler = () => setShowMenu(s => !s);

  return (
    <UserMenuContainer>
      {/*
      WARNING! Our monitoring tool (hatch-mon) looks at the component below
      and clicks it during it's run. If you change the testId or remove
      the component, it will think the site is down. Please update hatch-mon
      (or ask how / someone else to) as well if you remove this.
      */}
      <UserMenuHeader onClick={clickHandler} data-testid="userMenuHeader">
        <MxReactIcon Icon={Gear} color={AppColors.neutral.navy} size="m" />
      </UserMenuHeader>
      {showMenu && (
        <UserMenuItemContainer ref={menuItemsRef} data-testid="userMenu">
          <RoleBasedComponent
            require={[
              UserRole.ADMIN,
              UserRole.FIELD_OPERATOR,
              UserRole.CUSTOMER_SUPPORT,
            ]}
          >
            <UserMenuItem
              onClick={() => {
                clickHandler();
                navigate("/admin");
              }}
            >
              Admin Interface
            </UserMenuItem>
            <UserMenuSeparator />
          </RoleBasedComponent>
          {authorization.hasDemandManagement() && (
            <UserMenuItem
              onClick={() => {
                clickHandler();
                navigate(`/demand-management/${organizationId ?? ""}`);
              }}
            >
              <FormattedMessage id="demandManagement.title" />
            </UserMenuItem>
          )}
          <UserMenuItem
            onClick={() => {
              tracking.fireEvent("SettingsLoadUserSettingsPage", {});
              clickHandler();
              navigate("/settings/user");
            }}
          >
            <FormattedMessage id="userMenu.userSettings" />
          </UserMenuItem>
          <RoleBasedComponent
            require={[
              UserRole.CUSTOMER_ADMIN,
              UserRole.CUSTOMER_SUPPORT,
              UserRole.ADMIN,
            ]}
          >
            <UserMenuItem
              onClick={() => {
                clickHandler();
                navigate("/new-user");
              }}
            >
              <FormattedMessage id="newUserRequest.title" />
            </UserMenuItem>
          </RoleBasedComponent>
          <UserMenuItem>
            <a
              style={{
                color: AppColors.neutral.navy,
                fontSize: "14px",
              }}
              href="/help"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => {
                tracking.fireEvent("SettingsLoadProductHelp", {
                  userId,
                  url: window.location.href,
                });
                clickHandler();
              }}
            >
              <FormattedMessage id="userMenu.productHelp" />
              <MxReactIcon
                Icon={LinkOut}
                color={AppColors.primary["msr-green"]}
                style={{
                  paddingLeft: "3px",
                }}
              />
            </a>
          </UserMenuItem>
          <UserMenuItem onClick={sendEmail}>
            <FormattedMessage id="userMenu.contactUs" />
          </UserMenuItem>
          {/*
          WARNING! Our monitoring tool (hatch-mon) looks at the component below
          and clicks it during it's run. If you change the testId or remove
          the component, it will think the site is down. Please update hatch-mon
          (or ask how / someone else to) as well if you remove this.
          */}
          <UserMenuItem onClick={logout} data-testid="logout">
            <FormattedMessage id="userMenu.logout" />
          </UserMenuItem>
        </UserMenuItemContainer>
      )}
    </UserMenuContainer>
  );
};

export default UserMenu;
